.default {
  .pelumas {
    background-color: #D10000;
  }
  h1.heading {
    font-size: 60px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    color: #ffffff;
  }
  p.heading.p__color {
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: #ffffff;
    margin-left: 35px;
    margin-right: 35px;
  }
  .pelumas__box {
    border-radius: 10px;
    width: 105%;
    background-color: #191919;
    padding: 30px;
    transition: 0.4s ease;
  }
  .pelumas__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #7e0000, #460000);
  }
  .pelumas__box:hover p.pelumas__text.p__color {
    color: #fff !important;
  }
  p.pelumas__text.p__color {
    margin-bottom: 2;
    align-items: center;
    text-align: left;
  }
  h1.pelumas__text {
    margin-top: 3px;
    font-size: 19px;
    font-family: Poppins, sans-serif;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  img.pelumas__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    opacity: 0.7;
    position: relative;
  }
  button.btn_stuck {
    margin: 10px 0px;
    background: #000000;
    border: 2px solid #000000;
    width: 170px;
    position: absolute;
    right: 160px;
  }
  img.keterangan__img {
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
  p.keterangan__pelumas__text.p__color {
    align-items: center;
    text-align: justify;
    color: #ffffff;
  }
  h1.keterangan__pelumas__text {
    margin-top: 15px;
    font-size: 19px;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  h1.produk__pelumas__text {
    margin-top: 0;
    font-size: 22px;
    padding: 3px 0px;
    margin-bottom: 5px;
    text-align: center;
    color: #ffffff;
  }
  .pelumas__box:hover .btn__cek {
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: #ec131c !important;
  }
  .btn__cek {
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    border: 2px solid #ec131c;
    background-color: #ec131c;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 35px;
    width: 155px;
    margin: 0px 0px;
    cursor: pointer;
  }
  .kunjungi_web {
    font-size: 15px;
    font-weight: 700;
    border-radius: 25px;
    border: 2px solid #000000;
    background-color: #000000;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 45px;
    width: 98.5%;
    margin: 0px 0px;
    position: relative;
    left: 20px;
    cursor: pointer;
  }
  .bintang {
    color: rgba(250, 192, 0, 0.884);
  }
  .backhome {
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    border: 2px solid #000000;
    background-color: #000000;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 180px;
    margin: 0px 0px;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 40px;
    font-family: 'Josefin Sans', sans-serif;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .pelumas__box {
      padding: 32px;
    }
  }
  @media only screen and (max-width: 425px) {
    .backhome {
      font-size: 18px;
      height: 40px;
      width: 150px;
      left: 36px;
    }
    .pelumas__box {
      width: 100%;
    }
    button.btn_stuck {
      right: 185px;
    }
  }
}

.chritmas {
  .pelumas {
    background-color: #ffffff;
  }
  h1.heading {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
    color: #5c0000;
    font-family: 'Redressed', cursive;
  }
  p.heading.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: #5c0000;
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 35px;
    margin-right: 35px;
  }
  .pelumas__box {
    border-radius: 10px;
    width: 105%;
    background-color: #5c0000;
    padding: 30px;
    transition: 0.4s ease;
  }
  .pelumas__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #ff0000, #9c2121);
  }
  .pelumas__box:hover p.pelumas__text.p__color {
    color: #fff !important;
  }
  p.pelumas__text.p__color {
    margin-bottom: 2;
    align-items: center;
    text-align: left;
    font-family: 'Josefin Sans', sans-serif;
  }
  h1.pelumas__text {
    margin-top: 3px;
    font-size: 19px;
    font-family: 'Redressed', cursive;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  img.pelumas__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    opacity: 0.7;
    position: relative;
  }
  button.btn_stuck {
    margin: 10px 0px;
    background: #000000;
    border: 2px solid #000000;
    width: 170px;
    position: absolute;
    right: 160px;
  }
  img.keterangan__img {
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
  p.keterangan__pelumas__text.p__color {
    align-items: center;
    text-align: justify;
    color: #5c0000;
  }
  h1.keterangan__pelumas__text {
    margin-top: 15px;
    font-size: 19px;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #5c0000;
  }
  h1.produk__pelumas__text {
    margin-top: 0;
    font-size: 22px;
    padding: 3px 0px;
    margin-bottom: 5px;
    text-align: center;
    color: #ffffff;
  }
  .pelumas__box:hover .btn__cek {
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: #ec131c !important;
  }
  .btn__cek {
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    border: 2px solid #000000;
    background-color: #000000;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 35px;
    width: 150px;
    margin: 0px 0px;
    cursor: pointer;
  }
  .kunjungi_web {
    font-size: 15px;
    font-weight: 700;
    border-radius: 25px;
    border: 2px solid #5c0000;
    background-color: #5c0000;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 45px;
    width: 98.5%;
    margin: 0px 0px;
    position: relative;
    left: 20px;
    cursor: pointer;
  }
  .bintang {
    color: rgba(250, 192, 0, 0.884);
  }
  .backhome {
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    border: 2px solid #000000;
    background-color: #000000;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 180px;
    margin: 0px 0px;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 40px;
    font-family: 'Josefin Sans', sans-serif;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .pelumas__box {
      padding: 32px;
    }
  }
  @media only screen and (max-width: 425px) {
    .backhome {
      font-size: 18px;
      height: 40px;
      width: 150px;
      left: 36px;
    }
    .pelumas__box {
      width: 100%;
    }
    button.btn_stuck {
      right: 185px;
    }
  }
}


.eid_mubarak {
  .pelumas {
      background-color: #ffffff;
    }
  h1.heading {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
    color: #1E311E;
    font-family: 'Great Vibes', cursive;
  }
  p.heading.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: #1E311E;
    font-family: 'Oxygen', sans-serif;
    margin-left: 35px;
    margin-right: 35px;
  }
  .pelumas__box {
    border-radius: 10px;
    width: 105%;
    background-color: #1E311E;
    padding: 30px;
    transition: 0.4s ease;
  }
  .pelumas__box:hover {
    transform: translateY(5px);
    background-color: #CE8037;
  }
  .pelumas__box:hover p.pelumas__text.p__color {
    color: #fff !important;
  }
  p.pelumas__text.p__color {
    margin-bottom: 2;
    align-items: center;
    text-align: left;
    font-family: 'Oxygen', sans-serif;
  }
  h1.pelumas__text {
    margin-top: 3px;
    font-size: 19px;
    font-family: 'Great Vibes', cursive;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  img.pelumas__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    opacity: 0.7;
    position: relative;
  }
  button.btn_stuck {
    margin: 10px 0px;
    background: #000000;
    border: 2px solid #000000;
    width: 170px;
    position: absolute;
    right: 160px;
  }
  img.keterangan__img {
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
  p.keterangan__pelumas__text.p__color {
    align-items: center;
    text-align: justify;
    color: #1E311E;
  }
  h1.keterangan__pelumas__text {
    margin-top: 15px;
    font-size: 19px;
    padding: 3px 0px;
    margin-bottom: 5px;
    color: #1E311E;
  }
  h1.produk__pelumas__text {
    margin-top: 0;
    font-size: 22px;
    padding: 3px 0px;
    margin-bottom: 5px;
    text-align: center;
    color: #ffffff;
  }
  .pelumas__box:hover .btn__cek {
    border: 2px solid #1E311E;
    background-color: #1E311E;
    color: #ffffff !important;
  }
  .btn__cek {
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    border: 2px solid #CE8037;
    background-color: #CE8037;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 35px;
    width: 150px;
    margin: 0px 0px;
    cursor: pointer;
  }
  .kunjungi_web {
    font-size: 15px;
    font-weight: 700;
    border-radius: 25px;
    border: 2px solid #CE8037;
    background-color: #CE8037;
    color: #fff !important;
    letter-spacing: 1px;
    transition: 0.4s all ease;
    height: 45px;
    width: 98.5%;
    margin: 0px 0px;
    position: relative;
    left: 20px;
    cursor: pointer;
  }
  .bintang {
    color: rgba(250, 192, 0, 0.884);
  }
  .backhome {
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    border: 2px solid #000000;
    background-color: #000000;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 180px;
    margin: 0px 0px;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 40px;
    font-family: 'Oxygen', sans-serif;
  }

  /* RESPONSIVE */
  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .pelumas__box {
      padding: 32px;
    }
  }
  @media only screen and (max-width: 425px) {
    .backhome {
      font-size: 18px;
      height: 40px;
      width: 150px;
      left: 36px;
    }
    .pelumas__box {
      width: 100%;
    }
    button.btn_stuck {
      right: 185px;
    }
  }
}