.default {
  .about {
    background: #004aad;
  }
  .coll__2 {
    max-width: 40%;
    display: flex;
    padding: 10px;
    justify-content: center;
    flex-direction: column;
  }
  .colll__2 {
    max-width: 60%;
    display: flex;
    padding: 10px;
    justify-content: center;
    flex-direction: column;
  }
  img.about__img {
    width: 100%;
  }
  h1.about__heading {
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: 60px;
  }
  a.bottom__to__top {
    height: 55px;
    width: 55px;
    position: fixed;
    bottom: 3%;
    right: 2%;
    background-color: #004aad;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    transition: 0.4s all ease;
    z-index: 1000;
  }
  .p__font {
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
    text-align: justify;
  }
  a.bottom__to__top.active {
    display: flex;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .p__color {
      font-size: 15px !important;
    }
  }
  @media only screen and (max-width: 455px) {
    button.about.btn.pointer {
      text-transform: capitalize;
      width: 165px;
      height: 50px;
      font-size: 13px;
      transition: 0.4s ease;
    }
    a.bottom__to__top {
      height: 45px;
      width: 45px;
      bottom: 4%;
      right: 3%;
    }
    .chev {
      width: 20px;
      height: 20px;
    }
    h1.about__heading {
      font-size: 45px;
      margin-bottom: 5px;
    }
    .p__font {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0px;
    }
    .coll__2 {
      max-width: 100%;
    }
    .colll__2 {
      max-width: 100%;
    }
  }
}

.chritmas {
  .about {
    background-image: url(../img/theme/nataltheme/aboutnatal.png);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  img.about__img {
    border-radius: 20px;
    width: 100%;
  }
  h1.about__heading {
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-weight: 700;
    text-align: start;
    font-family: "Redressed", cursive;
    font-size: 60px;
  }
  a.bottom__to__top {
    height: 55px;
    width: 55px;
    position: fixed;
    bottom: 3%;
    right: 2%;
    background-color: #5c0000;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    transition: 0.4s all ease;
    z-index: 1000;
  }
  .p__font {
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: 30px;
    font-family: "Josefin Sans", sans-serif;
    margin-bottom: 15px;
    text-align: justify;
  }
  a.bottom__to__top.active {
    display: flex;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .p__color {
      font-size: 15px !important;
    }
  }
  @media only screen and (max-width: 425px) {
    button.about.btn.pointer {
      text-transform: capitalize;
      width: 165px;
      height: 50px;
      font-size: 13px;
      transition: 0.4s ease;
    }
    .about {
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.eid_mubarak {
  .about {
    background-image: url(../img/theme/lebarantheme/aboutlebaran.png);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  img.about__img {
    border-radius: 20px;
    width: 100%;
  }
  h1.about__heading {
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-weight: 700;
    text-align: start;
    font-family: "Great Vibes", cursive;
    font-size: 60px;
  }
  a.bottom__to__top {
    height: 55px;
    width: 55px;
    position: fixed;
    bottom: 3%;
    right: 2%;
    background-color: #1e311e;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    transition: 0.4s all ease;
    z-index: 1000;
  }
  .p__font {
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: 30px;
    font-family: "Oxygen", sans-serif;
    margin-bottom: 15px;
    text-align: justify;
  }
  a.bottom__to__top.active {
    display: flex;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .p__color {
      font-size: 15px !important;
    }
  }
  @media only screen and (max-width: 425px) {
    button.about.btn.pointer {
      text-transform: capitalize;
      width: 165px;
      height: 50px;
      font-size: 13px;
      transition: 0.4s ease;
    }
  }
}
