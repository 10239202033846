.default {
  .company {
    background-color: #ffffff;
  }
  .coll__3 {
    max-width: 33.333333%;
    display: flex;
    padding: 20px;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }
  img.project__img {
    border-radius: 10px;
    width: 75%;
    object-fit: cover;
    transition: 0.4s;
    position: relative;
  }
  img.project__img::after {
    content: "";
    width: 70%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect {
    width: 75%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 45px;
    background-color: #919191;
    border-radius: 10px;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box:hover .mask__effect {
    display: inline;
    transform: scale(1.1);
  }
  .project__box:hover img.project__img {
    transform: scale(1.1);
  }
  .project__meta.absolute {
    top: 0%;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    width: 100%;
  }
  h1.headingcompany {
    color: #4b4b4b;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
  }
  p.project__text {
    color: #4b4b4b;
    font-size: 18px;
    font-family: Poppins, sans-serif;
    text-align: center;
  }
  a.project__btn {
    transition: 0.4s ease;
    color: #4b4b4b;
    margin-top: 10px;
    font-size: 27px;
    text-align: center;
    margin-bottom: 10px;
  }
  // a.project__btn:hover {
  //   background-color: #D10000;
  //   border: 2px solid #D10000;
  // }
  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    h5.project__text {
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 20px;
    }
    h4.project__text {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 455px) {
    img.project__img {
      width: 100%;
    }
    h1.headingcompany {
      font-size: 45px;
      margin-bottom: 0px;
    }
    a.project__btn {
      margin-top: 0px;
      font-size: 18px;
      margin-bottom: 5px;
    }
    p.project__text {
      font-size: 16px;
    }
  }
}

.chritmas {
  .company {
    background-image: url(../img/theme/nataltheme/company.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  img.project__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    opacity: 1;
    position: relative;
  }
  img.project__img::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #860d13;
    border-radius: 10px;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box:hover .mask__effect {
    display: inline;
    transform: scale(1.1);
  }
  .project__box:hover img.project__img {
    transform: scale(1.1);
  }
  .project__meta.absolute {
    top: 0%;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    width: 100%;
  }
  h1.headingcompany {
    color: #860d13;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    font-family: "Redressed", cursive;
    margin-bottom: 20px;
  }
  p.headingcompany {
    color: #860d13;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 5px;
    font-family: "Josefin Sans", sans-serif;
  }
  h4.project__text {
    font-size: 22px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    padding-bottom: 20px;
    font-family: "Josefin Sans", sans-serif;
  }
  a.project__btn {
    padding: 12px 35px;
    border: 2px solid #ffffff87;
    border-radius: 5px;
    transition: 0.4s ease;
    font-family: "Josefin Sans", sans-serif;
  }
  a.project__btn:hover {
    background-color: #860d13;
    border: 2px solid #860d13;
    font-family: "Josefin Sans", sans-serif;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    h5.project__text {
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 20px;
    }
    h4.project__text {
      font-size: 18px;
    }
  }
}

.eid_mubarak {
  .company {
    background-image: url(../img/theme/lebarantheme/companylebaran.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  img.project__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    opacity: 1;
    position: relative;
  }
  img.project__img::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1e311e;
    border-radius: 10px;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box:hover .mask__effect {
    display: inline;
    transform: scale(1.1);
  }
  .project__box:hover img.project__img {
    transform: scale(1.1);
  }
  .project__meta.absolute {
    top: 0%;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    width: 100%;
  }
  h1.headingcompany {
    color: #1e311e;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    font-family: "Great Vibes", cursive;
    margin-bottom: 20px;
  }
  p.headingcompany {
    color: #1e311e;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 5px;
    font-family: "Oxygen", sans-serif;
  }
  h4.project__text {
    font-size: 22px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    padding-bottom: 20px;
    font-family: "Oxygen", sans-serif;
  }
  a.project__btn {
    padding: 12px 35px;
    border: 2px solid #ffffff87;
    border-radius: 5px;
    transition: 0.4s ease;
    font-family: "Oxygen", sans-serif;
  }
  a.project__btn:hover {
    background-color: #1e311e;
    border: 2px solid #1e311e;
    font-family: "Oxygen", sans-serif;
  }
  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    h5.project__text {
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 20px;
    }
    h4.project__text {
      font-size: 18px;
    }
  }
}
