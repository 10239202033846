a {
  text-decoration: none;
  color: #fff;
}

.white {
  color: #fff !important;
}
.navbar_bg {
  background-color: #000000;
}
li {
  overflow: hidden;
}
li.nav__items {
  font-size: 18px;
  padding-bottom: 5px;
  color: #fff;
}

.header {
  transition: 0.4s all ease;
}
.header.active {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 0px 30px;
  background-color: #ec131c;
}

.navbar-dashboard {
  background: #fff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgrey;
}
.nav_icon {
  display: none;
}

.nav_icon > i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 15px;
  font-weight: 700;
}

.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

.navbar__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right > a > i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #fff;
  padding: 7px;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.jmb__heading {
  margin-bottom: 12px;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}

@media only screen and (max-width: 768px) {
  .nav_icon {
    display: inline;
  }
}

@media only screen and (max-width: 425px) {
  .navbar__left {
    display: none;
  }
}
