.main-regis {
    text-align: center;
    justify-content: center;
    display: flex;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #e1e3eb;
  }
  .sub-main {
    display: flex;
    justify-content: center;
    height: 80%;
    width: 35%;
    box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
    padding-top: 30px;
    border-radius: 60px;
    background-color: white;
  }
  .imgs {
    padding-top: 10px;
    justify-content: center;
    display: flex;
  }
  .container-image {
    background-color: rgb(223, 221, 221);
    border-radius: 150px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100px;
    width: 100px;
  }
  .fw {
    font-size: xx-large;
    color: #ec131c;
  }
  h1 {
    color: #000;
    font-weight: 600;
  }
  input {
    width: 300px;
    height: 50px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 2px 0px #888;
    border: none;
    outline: none;
    color: #000;
    background-color: #fff;
  }
  .box__input {
    padding-left: 20px;
    font-size: 15px;
    width: 380px;
    height: 50px;
  }
  .second-input {
    padding-top: 20px;
  }
  .third-input {
    padding-top: 20px;
  }
  .fourth-input {
    padding-top: 20px;
  }
  .fifth-input {
    padding-top: 20px;
  }
  button.regis-btn {
    width: 380px;
    height: 50px;
    border-radius: 60px;
    background-color: #ec131c;
    color: white;
    font-size: 15px;
    border: none;
    margin-bottom: 15px;
  }
  .regis-button {
    padding-top: 25px;
  }
  a {
    color: rgb(255, 0, 0);
  }
  .back {
      padding-bottom: 20px;
  }
  
  /* RESPONSIVE */
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 425px) {
  .main-regis {
    padding-bottom: 250px;
  }
  .sub-main {
    height: 660px;
    width: 90%;
  }
  #fullname {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  #email {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  #divisi {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  #re_password {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  .second-input {
    padding-top: 10px;
  }
  .third-input {
    padding-top: 10px;
  }
  .fourth-input {
    padding-top: 10px;
  }
  .fifth-input {
    padding-top: 10px;
  }
  button.regis-btn {
    width: 300px;
    height: 45px;
    font-size: 15px;
  }
}