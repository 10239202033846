main {
  background: #f3f4f6;
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 20px 35px;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.main_greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.main_greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin: 20px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  padding: 25px;
  border-radius: 5px;
  background: #ffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffff;
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_inner > span {
  font-size: 25px;
}

.lightblue {
  color: #35a4ba;
}

.yellow {
  color: darkgoldenrod;
}

.green {
  color: darkgreen;
}

.purple {
  color: blueviolet;
}

.text-primary {
  font-size: 14px;
  color: #a5aaad;
  font-weight: 700;
  margin-top: 4px;
}

.text-title {
  font-weight: 700;
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
}
