a {
  text-decoration: none;
  color: #fff;
}

ul.sidebar.d__flex {
  flex-wrap: wrap;
  height: 100%;
  position: fixed;
  right: 0%;
  background: #ec131c;
  justify-content: center;
  width: 300px;
  top: 0%;
  flex-direction: column;
}

#sidebar {
  background: #020509;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
  color: #fff;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #e65061;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sb_text {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
  margin-left: 8px;
}

.sidebar_link > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  position: static;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  height: 100%;
}

.jmb__heading {
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}
@media only screen and (max-width: 845px) {
  #sidebar {
    display: none;
  }
  .sidebar__title > i {
    display: inline;
  }
  .nav_icon {
    display: inline;
  }
}
@media only screen and (max-widt: 978px) {
  .nav_icon {
    display: inline;
  }
}
@media only screen and (max-widt: 480px) {
  .navbar__left {
    display: none;
  }
}
