.default {
  .service {
    background-color: #ff0b07;
  }
  .loker__bg {
    background-color: #ff0b07;
  }
  .home__loker {
    padding: 0px 50px;
  }
  h1.home__text {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    color: #ffffff !important;
    text-align: center;
    font-family: Montserrat, sans-serif;
  }
  h2.home__text {
    color: #ffffff;
    font-size: 35px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    position: absolute;
    margin-top: 40px;
  }
  h3.home__text {
    margin-bottom: 100px;
  }
  h4.home__text {
    font-size: 20px;
    line-height: 25px;
    font-family: Poppins, sans-serif;
    width: 50%;
    text-align: left;
  }
  h1.service__text {
    font-size: 60px;
    font-family: Poppins, sans-serif;
    padding: 10px 0px;
    color: #ffffff;
    margin-bottom: 35px;
  }
  p.service__text {
    font-size: 23px;
    line-height: 28px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-weight: 500;
  }
  .colm__2 {
    max-width: 50%;
    display: flex;
    padding: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  img.service__img {
    width: 60%;
  }
  .jobs {
    font-size: 18px;
    font-weight: 500;
    border-radius: 0px;
    border: 2px solid #e0fe9c;
    background-color: #e0fe9c;
    color: #000000 !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 270px;
    margin: 0px 0px;
    cursor: pointer;
    left: 105px;
    font-family: Poppins, sans-serif;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .service__box {
      padding: 32px;
    }
  }
  @media only screen and (max-width: 455px) {
    h1.service__text {
      font-size: 45px;
      padding: 0px 0px;
      margin-bottom: 25px;
    }
    p.service__text {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0px;
    }
    .jobs {
      font-size: 16px;
      height: 40px;
      width: 220px;
    }
    img.service__img {
      width: 300px;
    }
  }
}

.chritmas {
  .service {
    background-image: url(../img/theme/nataltheme/service.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1.headingservice {
    font-size: 60px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    color: white;
    font-family: "Redressed", cursive;
  }
  p.headingservice.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: white;
    font-family: "Josefin Sans", sans-serif;
  }
  .service__box {
    border-radius: 10px;
    width: 100%;
    background-color: #ffffff;
    padding: 55px;
    transition: 0.4s ease;
  }
  .service__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #610005, #750c11);
  }
  .service__box:hover .icon {
    color: #fff;
  }
  .service__box:hover p.service__text.p__color {
    color: #fff !important;
  }
  .service__box:hover h1.service__text {
    color: #fff !important;
  }
  .icon {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 23px;
    display: inline-flex;
    color: #000000;
  }
  h1.service__text {
    font-size: 19px;
    font-family: "Redressed", cursive;
    padding: 10px 0px;
    color: #000000;
  }
  p.service__text.p__color {
    margin-bottom: 0;
    align-items: center;
    font-family: "Josefin Sans", sans-serif;
    color: #000000;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .service__box {
      padding: 32px;
    }
  }
}

.eid_mubarak {
  .service {
    background-color: #ffffff;
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1.headingservice {
    font-size: 60px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    color: #1e311e;
    font-family: "Great Vibes", cursive;
  }
  p.headingservice.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
    color: #1e311e;
    font-family: "Oxygen", sans-serif;
  }
  .service__box {
    border-radius: 10px;
    width: 100%;
    background-color: #1e311e;
    padding: 55px;
    transition: 0.4s ease;
  }
  .service__box:hover {
    transform: translateY(5px);
    background-color: #ce8037;
  }
  .service__box:hover .icon {
    color: #fff;
  }
  .service__box:hover p.service__text.p__color {
    color: #fff !important;
  }
  .service__box:hover h1.service__text {
    color: #fff !important;
  }
  .icon {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 23px;
    display: inline-flex;
    color: #ffffff;
  }
  h1.service__text {
    font-size: 19px;
    font-family: "Great Vibes", cursive;
    padding: 10px 0px;
    color: #ffffff;
  }
  p.service__text.p__color {
    margin-bottom: 0;
    align-items: center;
    font-family: "Oxygen", sans-serif;
    color: #ffffff;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 1024px) {
    .service__box {
      padding: 32px;
    }
  }
}
