* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
body {
  width: 100%;
  height: 100%;
  background-color: #ec131c;
  font-family: sans-serif;
}
a {
  text-decoration: none;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-size: 18px;
  color: #fff;
  font-family: Poppins, sans-serif;
}
li {
  overflow: hidden;
}
span {
  display: block;
}
p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.red {
  color: #ec131c !important;
}
.white {
  color: #fff !important;
}
.black {
  color: #000 !important;
}
.d__flex {
  display: flex;
}
.align__items__center {
  align-items: center;
}
.justify__content__center {
  justify-content: center;
}
.justify__content__space__between {
  justify-content: space-between;
}
.mx__15 {
  margin: 0px 15px;
}
.px__30 {
  padding: 0px 30px;
}
.pxy__30 {
  padding: 30px;
}
.container {
  width: 92%;
  margin: 0px auto;
}
.pz__10 {
  padding: 10px 0px;
}
.sweet {
  color: #ec131c;
}
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
}
.col__2 {
  max-width: 50%;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
}
.col__3 {
  max-width: 33.333333%;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.component__space {
  padding: 100px 0px;
}
.pointer {
  cursor: pointer;
}
svg {
  stroke-width: 1 !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
strong {
  color: #ec131c;
  font-weight: 600;
}
input {
  color: rgba(198, 201, 216, 0.75);
}
button.btn {
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #ec131c;
  background-color: #ec131c;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.4s all ease;
  height: 50px;
  width: 180px;
  margin: 0px 10px;
}
.table {
  font-family: sans-serif;
  color: #232323;
  border-collapse: collapse;
}
.table,
th,
td {
  border: 1px solid #999;
  padding: 8px 20px;
}
.p__color {
  color: rgba(198, 201, 216, 0.75);
  font-size: 18px;
  line-height: 30px;
  font-family: Poppins, sans-serif;
  margin-bottom: 15px;
}
button.about.btn.pointer:hover {
  background: #fff;
  color: #000 !important;
}
.heading {
  color: #fff;
}
/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .col__2 {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .col__3 {
    max-width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  .col__3 {
    max-width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  span.copyright {
    font-size: 13px !important;
  }
  .footer.d__flex.align__items__center.justify__content__space__between.pz-10 {
    flex-wrap: wrap;
  }
}
