#posisi {
  width: 100%;
  height: 60px;
  font-size: 15px;
  border-radius: 5px;
}

#btn_remove {
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #ec131c;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
}

#btn_tmb {
  border-radius: 5px;
  color: #ffffff;
  background: #ec131c;
  border: #ec131c;
  width: 40%;
  padding: 25px;
  padding-left: 90px;
  font-size: 15px;
}

#kualifikasi {
  border-radius: 5px;
}

#download {
  border-radius: 5px;
}

// .output {
//   padding-top: 300px;
//   column-count: 3;
//   position: relative;
//   width: 1000px;
//   height: 200px;
//   padding-left: 140px;
// }
// .output-kualifikasi {
//   padding-top: 20px;
//   column-count: 2;
//   position: relative;
//   width: 1000px;
//   height: 400px;
//   padding-left: 140px;
// }

.theme1_1x1 {
  .posisi_loker {
    font-family: "Laila", sans-serif;
    font-size: 47px;
    color: #ed1e27;
    position: relative;
    left: 50px;
    top: 300px;
  }

  .kualifikasi {
    font-family: "Laila", sans-serif;
    font-size: 30px;
    color: #0f2736;
    position: relative;
    top: 300px;
    left: 50px;
  }

  .isi_kualifikasi {
    font-family: "Laila", sans-serif;
    font-size: 28px;
    color: #0f2736;
    position: relative;
    top: 300px;
    width: 92%;
    left: 50px;
  }
}

.theme2_1x1 {
  .posisi_loker {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bolder;
    font-size: 45px;
    color: #ED1E27;
    position: relative;
    left: 50px;
    top: 380px;
    height: 57px;
  }
  
  .kualifikasi {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 30px;
    color: #0F2736;
    position: relative;
    left: 50px;
    top: 390px;
  }
  
  .isi_kualifikasi {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 26px;
    color: #0F2736;
    position:relative;
    left: 50px;
    top: 390px;
    width: 92%;
  }
}

.theme3_1x1 {
  .posisi_loker {
    font-family: 'Atma', cursive;
    font-weight: bolder;
    font-size: 45px;
    color: #ED1E27;
    position:relative;
    top: 270px;
    left: 50px;
    height: 60px;
  }
  
  .kualifikasi {
    font-family: 'Atma', cursive;
    font-size: 30px;
    color: #0F2736;
    position:relative;
    top: 280px;
    left: 50px;
  }
  
  .isi_kualifikasi {
    font-family: 'Atma', cursive;
    font-size: 27px;
    color: #0F2736;
    position:relative;
    top: 280px;
    left: 50px;
    width: 92%;
  }
}

.theme4_4x5 {
  .posisi_loker {
    top: 300px;
    left: 50px;
    font-family: "Laila", sans-serif;
    font-size: 47px;
    color: #ed1e27;
    position: relative;
    column-count: 2;
    width: 1000px;
    height: 80px;
  }

  .kualifikasi {
    font-family: "Laila", sans-serif;
    font-size: 30px;
    color: #0f2736;
    position: relative;
    top: 300px;
    left: 50px;
  }

  .isi_kualifikasi {
    font-family: "Laila", sans-serif;
    font-size: 28px;
    color: #0f2736;
    position: relative;
    top: 300px;
    width: 92%;
    left: 50px;
  }
}

.theme5_4x5 {
  .posisi_loker {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bolder;
    font-size: 45px;
    color: #ED1E27;
    position: relative;
    left: 50px;
    top: 380px;
    column-count: 2;
    width: 1000px;
    height: 52px;
  }
  
  .kualifikasi {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 30px;
    color: #0F2736;
    position: relative;
    left: 50px;
    top: 390px;
  }
  
  .isi_kualifikasi {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 26px;
    color: #0F2736;
    position:relative;
    left: 50px;
    top: 390px;
    width: 92%;
  }
}

.theme6_4x5 {
  .posisi_loker {
    font-family: 'Atma', cursive;
    font-weight: bolder;
    font-size: 45px;
    color: #ED1E27;
    position:relative;
    top: 270px;
    left: 50px;
    column-count: 2;
    width: 1000px;
    height: 60px;
  }
  
  .kualifikasi {
    font-family: 'Atma', cursive;
    font-size: 30px;
    color: #0F2736;
    position:relative;
    top: 280px;
    left: 50px;
  }
  
  .isi_kualifikasi {
    font-family: 'Atma', cursive;
    font-size: 27px;
    color: #0F2736;
    position:relative;
    top: 280px;
    left: 50px;
    width: 92%;
  }
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}