.main-lgn {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 90px;
  padding-bottom: 165px;
  background-color: #e1e3eb;
}
.sub-main-lgn {
  display: flex;
  justify-content: center;
  height: 470px;
  width: 35%;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  padding-top: 30px;
  border-radius: 60px;
  background-color: white;
}
.imgs {
  padding-top: 10px;
  justify-content: center;
  display: flex;
}
.container-image {
  background-color: rgb(223, 221, 221);
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100px;
}
.fw {
  font-size: xx-large;
  color: #ec131c;
}
h1 {
  color: #000;
  font-weight: 600;
}
input.lgn-input {
  width: 300px;
  height: 50px;
  border-radius: 60px;
  box-shadow: inset 0px 0px 2px 0px #888;
  border: none;
  outline: none;
  color: #000;
  background-color: #fff;
}
#username {
  padding-left: 20px;
  font-size: 15px;
  width: 380px;
  height: 50px;
}
#password {
  padding-left: 20px;
  font-size: 15px;
  width: 380px;
  height: 50px;
}
.second-input {
  padding-top: 20px;
}
button {
  width: 380px;
  height: 50px;
  border-radius: 60px;
  background-color: #ec131c;
  color: white;
  font-size: 15px;
  border: none;
  margin-bottom: 15px;
  font-family: Poppins, sans-serif;
}
button:hover {
  background-color: #000000;
}
.login-button {
  padding-top: 25px;
}
.back__home {
  font-family: Poppins, sans-serif;
}
.back__home:hover {
  color: #000000;
}
a {
  color: rgb(255, 0, 0);
}

/* RESPONSIVE */
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 425px) {
  .main-lgn {
    padding-bottom: 250px;
  }
  .sub-main-lgn {
    height: 450px;
    width: 90%;
  }
  #username {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  #password {
    font-size: 13px;
    width: 310px;
    height: 45px;
  }
  button.login-btn {
    width: 300px;
    height: 45px;
    font-size: 15px;
  }
}