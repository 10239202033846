.default {
  .footer {
    background-color: #ff0b07;
  }
  .foo_1 {
    max-width: 40%;
    display: flex;
    padding: 20px 0px;
    justify-content: center;
    flex-direction: column;
  }
  .foo_2 {
    max-width: 30%;
    display: flex;
    padding: 20px 10px;
    justify-content: center;
    flex-direction: column;
  }
  .foo_3 {
    max-width: 30%;
    display: flex;
    padding: 20px 10px;
    justify-content: center;
    flex-direction: column;
  }
  h3.foo__text {
    font-size: 22px;
    line-height: 23px;
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
  }
  img.foo__img {
    width: 50%;
  }
  p.foo__text {
    margin-top: 70px;
  }

  /* RESPONSIVE */
  @media only screen and (max-width: 455px) {
    .foo_1 {
      max-width: 100%;
      text-align: center;
    }
    .foo_2 {
      max-width: 50%;
    }
    .foo_3 {
      max-width: 50%;
    }
    img.foo__img {
      width: 80%;
    }
    p.foo__text {
      position: absolute;
      margin-top: 230px;
    }
    h3.foo__text {
      font-size: 18px;
    }
    p.hire__text {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 375px) {
    h3.foo__text {
      font-size: 17px;
    }
    p.hire__text {
      font-size: 15px;
    }
    p.foo__text {
      position: absolute;
      margin-top: 230px;
      margin-left: -10px;
      font-size: 15px;
    }
  }
}

.chritmas {
  .footer {
    background-color: #5c0000;
  }
}

.eid_mubarak {
  .footer {
    background-color: #ce8037;
  }
}
