.default {
  .home {
    background-image: url(../img/homey.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 320px 0px;
    transition: margin-left 0.5s;
  }
  .white {
    color: #fff !important;
  }
  // NAVBAR //
  a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    margin-top: 2px;
  }
  .header {
    transition: 0.4s all ease;
    padding-top: 30px;
  }
  .header.active {
    background-color: #004aad;
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 28px 18px;
  }
  .navbar__bg {
    float: left;
  }
  ul.sidebar.d__flex {
    flex-wrap: wrap;
    height: 100%;
    position: fixed;
    right: 0%;
    background: #d10000;
    justify-content: center;
    width: 300px;
    top: 0%;
    flex-direction: column;
  }
  li {
    overflow: hidden;
  }
  li.nav__items {
    font-size: 18px;
    padding-bottom: 5px;
    color: #fff;
  }
  li.sideNavbar {
    padding: 25px 30px;
  }
  .lokertheme {
    font-size: 20px;
    font-weight: 500;
    border-radius: 25px;
    border: 2px solid #004aad;
    background-color: #004aad;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 170px;
    margin: 0px 0px;
    padding-left: 40px;
    padding-top: 8px;
    cursor: pointer;
    position: absolute;
    top: 525px;
    left: 105px;
    font-family: Poppins, sans-serif;
  }

  /* RESPONSIVE */
  @media only screen and (min-width: 769px) {
    div.sideNavbar {
      display: none;
    }
    .toggle__menu {
      display: none;
      cursor: pointer;
      z-index: 2;
    }
    ul.sidebar.d__flex {
      display: none;
    }
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 768px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 2;
      width: 100%;
      cursor: pointer;
    }
    .header {
      justify-content: space-between;
    }
    .navigation.pxy__30 {
      display: none;
    }
    h2.white.pz__10 {
      font-size: 50px !important;
    }
    svg.bi.bi-justify.white.pointer {
      width: 30px;
      height: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 455px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 2;
      width: 100%;
      cursor: pointer;
    }
    .header {
      padding-top: 5px;
      padding-left: 15px;
    }
    .header.active {
      padding-top: 5px;
      padding-bottom: 10px;
    }
    .home__bg {
      background-image: url(../img/homey-andro.png);
      padding: 260px 0px;
    }
    .lokertheme {
      display: none;
    }
    h1.home__text {
      font-size: 35px;
      margin-top: 0px;
      margin-left: 17px;
    }
    h2.home__text.pz__10 {
      font-size: 25px;
      margin-top: 120px;
      margin-left: 17px;
    }
    h3.home__text {
      margin-bottom: 150px;
    }
    h4.home__text {
      font-size: 15px;
      margin-left: 17px;
      width: 90%;
    }
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
    li.nav__items {
      display: none;
    }
  }
}

.chritmas {
  .home__bg {
    background-image: url(../img/theme/nataltheme/bgnatal.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 330px 0px;
  }
  .loker__bg {
    background-color: #860d13;
  }
  .white {
    color: #fff !important;
  }
  .home__loker {
    padding: 100px 0px;
  }
  h1.welcome-text {
    display: none;
  }
  h1.home__text {
    margin-top: 15px;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    color: #ffffff !important;
    position: absolute;
    margin-left: 310px;
    font-family: "Redressed", cursive;
  }
  h2.home__text {
    color: #001a02;
    font-size: 35px;
    line-height: 25px;
    font-weight: 800;
    font-family: "Redressed", cursive;
    position: absolute;
    margin-top: 80px;
  }
  h3.home__text {
    margin-bottom: 120px;
  }
  h4.home__text {
    font-size: 20px;
    line-height: 25px;
    font-family: "Josefin Sans", sans-serif;
    width: 50%;
    text-align: left;
  }
  // NAVBAR //
  a {
    text-decoration: none;
    color: #ffffff;
  }
  .header {
    transition: 0.4s all ease;
  }
  .header.active {
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #5c0000;
  }
  .navbar__bg {
    float: left;
  }
  ul.sidebar.d__flex {
    flex-wrap: wrap;
    height: 100%;
    position: fixed;
    right: 0%;
    background: #5c0000;
    justify-content: center;
    width: 300px;
    top: 0%;
    flex-direction: column;
  }
  li {
    overflow: hidden;
  }
  li.nav__items {
    font-size: 18px;
    padding-bottom: 5px;
    font-family: "Josefin Sans", sans-serif;
  }
  li.sideNavbar {
    padding: 25px 30px;
  }
  .lokertheme {
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    border: 2px solid #008d1b;
    background-color: #008d1b;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 300px;
    margin: 0px 0px;
    cursor: pointer;
    position: absolute;
    top: 370px;
    left: 70px;
    font-family: "Josefin Sans", sans-serif;
  }
  .logo {
    position: relative;
    bottom: 10px;
    margin-right: 15px;
  }
  .logo2 {
    position: relative;
    left: 26px;
    bottom: 15px;
  }

  /* RESPONSIVE */
  @media only screen and (min-width: 769px) {
    .toggle__menu {
      display: none;
      z-index: 98;
    }
    ul.sidebar.d__flex {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 98;
      width: 100%;
    }
    .header {
      justify-content: space-between;
    }
    .navigation.pxy__30 {
      display: none;
    }
    h2.white.pz__10 {
      font-size: 50px !important;
    }
    svg.bi.bi-justify.white.pointer {
      width: 30px;
      height: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 425px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 98;
      width: 100%;
    }
    .home__bg {
      background-image: url(../img/theme/nataltheme/bgnatal2.png);
      padding: 230px 0px;
    }
    .welcome-text {
      display: block;
      margin-top: 0px;
      text-align: center;
      line-height: 50px;
      font-size: 55px;
      font-family: "Karma", serif;
      color: rgb(255, 255, 255);
    }
    .lokertheme {
      font-size: 16px;
      font-weight: 500;
      height: 45px;
      width: 150px;
      margin: 0px 0px;
      position: absolute;
      top: 430px;
      left: 110px;
      z-index: 99;
    }
    h1.home__text {
      font-size: 35px;
      margin-top: 0px;
      margin-left: 17px;
    }
    h2.home__text.pz__10 {
      font-size: 25px;
      margin-top: 120px;
      margin-left: 17px;
    }
    h3.jarak {
      margin-bottom: 150px;
    }
    h4.home__text {
      font-size: 15px;
      margin-left: 17px;
      width: 90%;
    }
  }
}

.eid_mubarak {
  .home__bg {
    background-image: url(../img/theme/lebarantheme/lebaranbg.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 330px 0px;
  }
  .loker__bg {
    background-color: #1e311e;
  }
  .white {
    color: #fff !important;
  }
  .home__loker {
    padding: 100px 0px;
  }
  h1.welcome-text {
    display: none;
  }
  h1.home__text {
    margin-top: 15px;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    position: absolute;
    margin-left: 310px;
    color: rgb(255, 255, 255) !important;
    font-family: "Oxygen", sans-serif;
  }
  h2.home__text {
    font-size: 35px;
    line-height: 25px;
    font-weight: 800;
    position: absolute;
    margin-top: 80px;
    color: #ffffff;
    font-family: "Great Vibes", cursive;
  }
  h3.jarak {
    margin-bottom: 120px;
  }
  h4.home__text {
    font-family: "Oxygen", sans-serif;
    font-size: 20px;
    line-height: 25px;
    width: 50%;
    text-align: left;
  }
  a {
    text-decoration: none;
    color: #ffffff;
  }
  .header {
    transition: 0.4s all ease;
  }
  .header.active {
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #ce8037;
  }
  .navbar__bg {
    float: left;
  }
  ul.sidebar.d__flex {
    flex-wrap: wrap;
    height: 100%;
    position: fixed;
    right: 0%;
    background: #1e311e;
    justify-content: center;
    width: 300px;
    top: 0%;
    flex-direction: column;
  }
  li {
    overflow: hidden;
  }
  li.nav__items {
    font-size: 18px;
    padding-bottom: 5px;
    font-family: "Oxygen", sans-serif;
  }
  li.sideNavbar {
    padding: 25px 30px;
  }
  .lokertheme {
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    border: 2px solid #ce8037;
    background-color: #ce8037;
    color: #fff !important;
    transition: 0.4s all ease;
    height: 45px;
    width: 300px;
    margin: 0px 0px;
    cursor: pointer;
    position: absolute;
    top: 530px;
    right: 235px;
    font-family: "Oxygen", sans-serif;
    z-index: 99;
  }
  .logo {
    position: relative;
    bottom: 10px;
    margin-right: 15px;
  }
  .logo2 {
    position: relative;
    left: 26px;
    bottom: 15px;
  }

  /* RESPONSIVE */
  @media only screen and (min-width: 769px) {
    .toggle__menu {
      display: none;
      z-index: 98;
    }
    ul.sidebar.d__flex {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 98;
      width: 100%;
    }
    .header {
      justify-content: space-between;
    }
    .navigation.pxy__30 {
      display: none;
    }
    h2.white.pz__10 {
      font-size: 50px !important;
    }
    svg.bi.bi-justify.white.pointer {
      width: 30px;
      height: 30px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 425px) {
    .toggle__menu {
      display: flex;
      justify-content: flex-end;
      float: right;
      z-index: 98;
      width: 100%;
    }
    .home__bg {
      background-image: url(../img/theme/lebarantheme/lebaranbg2.png);
      padding: 230px 0px;
    }
    .welcome-text {
      display: block;
      margin-top: 0px;
      text-align: center;
      line-height: 50px;
      font-size: 55px;
      font-family: "Karma", serif;
      color: rgb(255, 255, 255);
    }
    .lokertheme {
      font-size: 16px;
      font-weight: 500;
      height: 45px;
      width: 150px;
      margin: 0px 0px;
      position: absolute;
      top: 430px;
      left: 110px;
    }
    h1.home__text {
      font-size: 35px;
      margin-top: 0px;
      margin-left: 17px;
    }
    h2.home__text.pz__10 {
      font-size: 25px;
      margin-top: 120px;
      margin-left: 17px;
    }
    h3.jarak {
      margin-bottom: 150px;
    }
    h4.home__text {
      font-size: 15px;
      margin-left: 17px;
      width: 90%;
    }
  }
}
