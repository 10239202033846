.default {
  .partner {
    background-color: #004aad;
  }
  .Blog__meta__p.absolute {
    top: 0%;
    padding: 200px 490px;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }
  a.blog.project__btn__p {
    transition: 0.4s all ease;
    padding: 10px;
    width: 160px;
    text-align: center;
    border-style: solid;
    border-width: 2px;
    border-color: #d6d6d6 !important;
    font-size: 20px;
    color: #d6d6d6;
  }
  // a.blog.project__btn__p:hover {
  //   background: #000000 !important;
  // }
  .Blog__meta__p:hover a.blog.project__btn__p {
    margin-bottom: 15px;
  }
  h1.headingpartners {
    color: #ffffff;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    text-align: center;
    margin-bottom: 50px;
    font-family: Montserrat, sans-serif;
  }
  p.headingpartners {
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    line-height: 5px;
    font-family: Poppins, sans-serif;
  }
  h4.project__text__p {
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #d10000;
  }
  .project__img__box {
    text-align: center;
    margin-bottom: 35px;
  }
  img.project__img__p {
    width: 80%;
    object-fit: cover;
    transition: 0.4s;
    position: relative;
  }
  img.project__img__p::after {
    content: "";
    width: 80%;
    height: 80%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect__p {
    margin-left: 96px;
    width: 83%;
    height: 98.8%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box__p:hover .mask__effect__p {
    display: inline;
    transform: scale(1.1);
  }
  .project__box__p:hover img.project__img__p {
    transform: scale(1.1);
  }
  .blog__meta__p {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 455px) {
    h1.headingpartners {
      font-size: 45px;
      margin-bottom: 25px;
    }
    .Blog__meta__p.absolute {
      top: 0%;
      padding: 70px 155px;
    }
    a.blog.project__btn__p {
      padding: 2px;
      width: 85px;
      font-weight: 100;
      font-size: 15px;
      color: #8b8b8b;
      border-color: #8b8b8b !important;
    }
  }
}

.chritmas {
  .partner {
    background-image: url(../img/theme/nataltheme/partners.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Blog__meta__p.absolute {
    top: 0%;
    padding-left: 0px;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }
  a.blog.project__btn__p {
    margin-bottom: -50px;
    transition: 0.4s all ease;
    border-radius: 5px;
    padding: 10px;
    margin-left: 15px;
    width: 130px;
    text-align: center;
    background: #860d13 !important;
    font-family: "Josefin Sans", sans-serif;
  }
  a.blog.project__btn__p:hover {
    background: #000000 !important;
  }
  .Blog__meta__p:hover a.blog.project__btn__p {
    margin-bottom: 15px;
  }
  h1.headingpartners {
    color: #860d13;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    font-family: "Redressed", cursive;
    margin-bottom: 20px;
  }
  p.headingpartners {
    color: #860d13;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 5px;
    font-family: "Josefin Sans", sans-serif;
  }
  h4.project__text__p {
    font-size: 22px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
    padding-bottom: 20px;
    padding-left: 15px;
    color: #860d13;
  }
  img.project__img__p {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    position: relative;
  }
  img.project__img__p::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect__p {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #860d13;
    border-radius: 10px;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box__p:hover .mask__effect__p {
    display: inline;
    transform: scale(1.1);
  }
  .project__box__p:hover img.project__img__p {
    transform: scale(1.1);
  }
  .blog__meta__p {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.eid_mubarak {
  .partner {
    background-image: url(../img/theme/lebarantheme/partnerslebaran.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Blog__meta__p.absolute {
    top: 0%;
    padding-left: 0px;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }
  a.blog.project__btn__p {
    margin-bottom: -50px;
    transition: 0.4s all ease;
    border-radius: 5px;
    padding: 10px;
    margin-left: 15px;
    width: 130px;
    text-align: center;
    background: #1e311e !important;
    font-family: "Oxygen", sans-serif;
  }
  a.blog.project__btn__p:hover {
    background: #000000 !important;
  }
  .Blog__meta__p:hover a.blog.project__btn__p {
    margin-bottom: 15px;
  }
  h1.headingpartners {
    color: #ffffff;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    font-family: "Great Vibes", cursive;
    margin-bottom: 20px;
  }
  p.headingpartners {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 5px;
    font-family: "Oxygen", sans-serif;
  }
  h4.project__text__p {
    font-size: 22px;
    font-family: "Oxygen", sans-serif;
    font-weight: 700;
    padding-bottom: 20px;
    padding-left: 15px;
    color: #1e311e;
  }
  img.project__img__p {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: 0.4s;
    position: relative;
  }
  img.project__img__p::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
  }
  .mask__effect__p {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1e311e;
    border-radius: 10px;
    transition: 0.4s;
    opacity: 0.3;
    display: none;
  }
  .project__box__p:hover .mask__effect__p {
    display: inline;
    transform: scale(1.1);
  }
  .project__box__p:hover img.project__img__p {
    transform: scale(1.1);
  }
  .blog__meta__p {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
