.default {
  .contact {
    background-color: #004aad;
  }
  .row__cont {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 50px;
  }
  h1.hire__text {
    font-size: 60px;
  }
  h3.hire__text {
    font-size: 22px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 700;
  }
  p.hire__text {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
  }

  /* RESPONSIVE */
  // @media only screen and (max-width: 1024px) {

  // }
  @media only screen and (max-width: 455px) {
    h1.hire__text {
      font-size: 45px;
    }
    h3.hire__text {
      font-size: 20px;
      font-weight: 700;
    }
    p.hire__text {
      font-size: 17px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}

.chritmas {
  .contact {
    background-image: url(../img/theme/nataltheme/contact.png);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .row__cont {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 50px;
  }
  h1.hire__text {
    font-size: 60px;
    font-family: "Redressed", cursive;
  }
  p.hire__text {
    font-size: 18px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.75);
    font-weight: 400;
    font-family: "Josefin Sans", sans-serif;
  }
  strong.hire__text {
    color: rgb(0, 0, 0);
  }
  input.hire {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1px;
    background: none;
    border: 2px solid hsla(0, 0%, 100%, 0.8);
    font-family: "Josefin Sans", sans-serif;
  }
  .contact__img {
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
  }
  .txt-pjg {
    height: 120px;
    background: none;
    padding: 0 20px;
    width: 100%;
    line-height: 46px;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1px;
    border: 2px solid hsla(0, 0%, 100%, 0.8);
    margin-bottom: 10px;
    font-family: "Josefin Sans", sans-serif;
  }
  button.btn.contact.pointer {
    margin: 20px 0px;
    background: #008d1b;
    border: 2px solid #008d1b;
    font-family: "Josefin Sans", sans-serif;
  }
}

.eid_mubarak {
  .contact {
    background-image: url(../img/theme/lebarantheme/contactlebaran.png);
    background-color: #1e311e;
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .row__cont {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 50px;
  }
  h1.hire__text {
    font-size: 60px;
    font-family: "Great Vibes", cursive;
  }
  p.hire__text {
    font-size: 18px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.75);
    font-weight: 400;
    font-family: "Oxygen", sans-serif;
  }
  strong.hire__text {
    color: #ce8037;
  }
  input.hire {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin: 20px 0px;
    outline: none;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1px;
    background: none;
    border: 2px solid hsla(0, 0%, 100%, 0.8);
  }
  .contact__img {
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
  }
  .txt-pjg {
    height: 120px;
    background: none;
    padding: 0 20px;
    width: 100%;
    line-height: 46px;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1px;
    border: 2px solid hsla(0, 0%, 100%, 0.8);
    margin-bottom: 10px;
  }
  button.btn.contact.pointer {
    margin: 20px 0px;
    background: #ce8037;
    border: 2px solid #ce8037;
    font-family: "Oxygen", sans-serif;
  }
}
