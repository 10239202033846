.main {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #e1e3eb;
}
.sub-main {
  display: flex;
  justify-content: center;
  height: 450px;
  width: 35%;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  padding-top: 30px;
  border-radius: 60px;
  background-color: white;
}
.imgs {
  padding-top: 10px;
  justify-content: center;
  display: flex;
}
.dashboard {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}
.container-image {
  background-color: rgb(223, 221, 221);
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100px;
}
.fw {
  font-size: xx-large;
  color: #ec131c;
}
.profile {
  height: 100px;
  width: 100px;
  border-radius: 130px;
}
h1 {
  color: #000;
  font-weight: 600;
}
input {
  width: 300px;
  height: 50px;
  border-radius: 60px;
  box-shadow: inset 0px 0px 2px 0px #888;
  border: none;
  outline: none;
  color: #000;
  background-color: #fff;
}
.email {
  padding-left: 20px;
  font-size: 15px;
  width: 380px;
  height: 50px;
}
.username {
  padding-left: 20px;
  font-size: 15px;
  width: 380px;
  height: 50px;
}
.password {
  padding-left: 20px;
  font-size: 15px;
  width: 380px;
  height: 50px;
}
.second-input {
  padding-top: 20px;
}
.third-input {
  padding-top: 20px;
}
.garis {
  margin-bottom: 10px;
}
button {
  width: 380px;
  height: 50px;
  border-radius: 60px;
  background-color: #ec131c;
  color: white;
  font-size: 15px;
  border: none;
  margin-bottom: 15px;
}
.login-button {
  padding-top: 25px;
}
.link {
  font-size: 15px;
  font-weight: 400;
}
a {
  color: rgb(255, 0, 0);
}

@media only screen and (max-width: 978px) {
  .dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}
